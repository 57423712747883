import React from 'react'
import './test.css'
import page15 from './pagePic/15.jpg'
import page16 from './pagePic/16.jpg'
import page17 from './pagePic/17.jpg'
import page18 from './pagePic/18.jpg'
import page19 from './pagePic/19.jpg'

function TestComponent2(props) {
  return (
    <>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">15</div>
        </div>
        <div className="page-new__content">
          <img src={page15} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">16</div>
        </div>
        <div className="page-new__content">
          <img src={page16} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">17</div>
        </div>
        <div className="page-new__content">
          <img src={page17} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">18</div>
        </div>
        <div className="page-new__content">
          <img src={page18} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">19</div>
        </div>
        <div className="page-new__content">
          <img src={page19} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
    </>
  )
}

export default TestComponent2
