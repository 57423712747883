import React, { useState } from 'react'
import './test.css'
import pageOne from './pagePic/1.jpg'
import pageTwo from './pagePic/2.jpg'
import pageThree from './pagePic/3.jpg'
import pageFour from './pagePic/4.jpg'
import page5 from './pagePic/5.jpg'
import page6 from './pagePic/6.jpg'
import page7 from './pagePic/7.jpg'
import meter15 from './meters/meter15.png'
import meter25 from './meters/meter25.png'
import meter45 from './meters/meter45.png'
import meter70 from './meters/meter70.png'
import meter100 from './meters/meter100.png'

function TestComponent(props) {
  const [data, setData] = useState(props?.data)
  return (
    <>
      <div className="page-one">
        <div className="page-one__image-wrapper">
          <img className="page-one__image" src={pageOne} />
          <div className="page-one__text-wrapper">
            <div className="page-one__text">{`${data?.FirstName} ${data?.LastName}`}</div>
            <div className="page-one__text">{`${data?.Title}`}</div>
            <div className="page-one__text">{`${data?.Company}`}</div>
          </div>
        </div>
      </div>
      <div className="page-two">
        <div className="page-two__header">
          <div className="page-two__no">2</div>
        </div>
        <img src={pageTwo} className="page-two__image" />

        <div className="page-two__footer-wrapper">
          <div className="page-two__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>

      <div className="page-three">
        <div className="page-three__header">
          <div className="page-three__no">3</div>
        </div>
        <img src={pageThree} className="page-three__image" />

        <div className="page-three__content">
          <div className="page-three__meter-wrapper">
            {data?.meterDecide == 0 && (
              <img src={meter15} className="page-three__meter-image" />
            )}
            {data?.meterDecide == 1 && (
              <img src={meter25} className="page-three__meter-image" />
            )}
            {data?.meterDecide == 2 && (
              <img src={meter45} className="page-three__meter-image" />
            )}
            {data?.meterDecide == 3 && (
              <img src={meter70} className="page-three__meter-image" />
            )}
            {data?.meterDecide == 4 && (
              <img src={meter100} className="page-three__meter-image" />
            )}
          </div>
          <div className="page-three__score-wrapper">
            <div className="page-three__color-text">{`${data?.score}`}</div>
            <div className="page-three__black-text">Out of 100</div>
          </div>
        </div>

        <div className="page-three__footer-wrapper">
          <div className="page-three__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>

      <div className="page-four">
        <div className="page-four__header">
          <div className="page-four__no">4</div>
        </div>
        <div className="page-four__image-wrapper">
          <img src={pageFour} className="page-four__image" />
          <div className="page-four__text-one">${`${data?.rfiNum}`}</div>
          <div className="page-four__text-two">${`${data?.iceNum}`}</div>
          <div className="page-four__text-three">${`${data?.vdcNum}`}</div>
          <div className="page-four__text-four">${`${data?.ddpNum}`}</div>
          <div className="page-four__text-five">${`${data?.ddmNum}`}</div>
          <div className="page-four__text-six">${`${data?.savingNum}`}</div>
        </div>

        <div className="page-four__footer-wrapper">
          <div className="page-four__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">5</div>
        </div>
        <div className="page-new__content">
          <img src={page5} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">6</div>
        </div>
        <div className="page-new__content">
          <img src={page6} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">7</div>
        </div>
        <div className="page-new__content">
          <img src={page7} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
    </>
  )
}

export default TestComponent
