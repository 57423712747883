import React, { useRef, useState } from 'react'
import './test.css'
import html2canvas from 'html2canvas'
import TestComponent from './TestComponent'
import TestComponent1 from './TestComponent1'
import TestComponent2 from './TestComponent2'
import Button from '../Button/Button'
import ThankYouModal from '../ThankYouModal/ThankYouModal'

function PrintPage(props) {
  const [modalShow, setModalShow] = useState(false)
  const printDocument = async (e) => {
    e.preventDefault()
    setModalShow(true)
    let canvas1 = await html2canvas(inputRef.current, { scale: 2 })
    const imgData = canvas1.toDataURL('image/jpeg')

    let canvas2 = await html2canvas(inputRef1.current, { scale: 2 })
    const imgData2 = canvas2.toDataURL('image/jpeg')

    let canvas3 = await html2canvas(inputRef2.current, { scale: 2 })
    const imgData3 = canvas3.toDataURL('image/jpeg')

    let numPages1 = 7
    let numPages2 = 7
    let numPages3 = 5

    let heightMultiplier = -844

    import('jspdf').then((jspdf) => {
      let doc = new jspdf.jsPDF('p', 'pt')

      if (canvas1) {
        for (let i = 0; i < numPages1; i++) {
          if (i > 0) {
            doc.addPage()
          }
          doc.addImage(
            imgData,
            'JPEG',
            0,
            heightMultiplier * i,
            canvas1.width / 2.66,
            canvas1.height / 2.66,
            null,
            'MEDIUM'
          )
        }
      }
      if (canvas2) {
        for (let i = 0; i < numPages2; i++) {
          doc.addPage()
          doc.addImage(
            imgData2,
            'JPEG',
            0,
            heightMultiplier * i,
            canvas2.width / 2.66,
            canvas2.height / 2.66,
            null,
            'MEDIUM'
          )
        }
      }
      if (canvas3) {
        for (let i = 0; i < numPages3; i++) {
          doc.addPage()
          doc.addImage(
            imgData3,
            'JPEG',
            0,
            heightMultiplier * i,
            canvas3.width / 2.66,
            canvas3.height / 2.66,
            null,
            'MEDIUM'
          )
        }
      }

      doc.save('download.pdf')
      // window.open(doc.output("bloburl"), "_blank")
    })
    // var doc = new jsPDF("p", "pt")
  }
  const inputRef = useRef(null)
  const inputRef1 = useRef(null)
  const inputRef2 = useRef(null)
  return (
    <div className="print">
      <ThankYouModal modalShow={modalShow} setModalShow={setModalShow}/>
      <div className="button-wrapper">
        <Button label="Download" onClick={printDocument} />
      </div>
      {props?.data && (
        <div className="AppPrint">
          <div id="divToPrint" ref={inputRef}>
            <TestComponent data={props.data} />
          </div>
          <div id="divToPrint" ref={inputRef1}>
            <TestComponent1 />
          </div>
          <div id="divToPrint" ref={inputRef2}>
            <TestComponent2 />
          </div>
        </div>
      )}
    </div>
  )
}

export default PrintPage
