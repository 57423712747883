import React from 'react'
import './test.css'
import page8 from './pagePic/8.jpg'
import page9 from './pagePic/9.jpg'
import page10 from './pagePic/10.jpg'
import page11 from './pagePic/11.jpg'
import page12 from './pagePic/12.jpg'
import page13 from './pagePic/13.jpg'
import page14 from './pagePic/14.jpg'

function TestComponent1(props) {
  return (
    <>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">8</div>
        </div>
        <div className="page-new__content">
          <img src={page8} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">9</div>
        </div>
        <div className="page-new__content">
          <img src={page9} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">10</div>
        </div>
        <div className="page-new__content">
          <img src={page10} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">11</div>
        </div>
        <div className="page-new__content">
          <img src={page11} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">12</div>
        </div>
        <div className="page-new__content">
          <img src={page12} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">13</div>
        </div>
        <div className="page-new__content">
          <img src={page13} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
      <div className="page-new">
        <div className="page-new__header">
          <div className="page-new__no">14</div>
        </div>
        <div className="page-new__content">
          <img src={page14} className="page-new__content-image" />
        </div>

        <div className="page-new__footer-wrapper">
          <div className="page-new__footer">
            Digital Construction Readiness Assessment Report
          </div>
        </div>
      </div>
    </>
  )
}

export default TestComponent1
