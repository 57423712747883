import React from 'react'
import Modal from 'react-bootstrap/Modal'

const ThankYouModal = (props) => {
  const { modalShow, setModalShow } = props

  return (
    <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div>Thank you!</div>
        <div>Your download will begin shortly.</div>
      </Modal.Body>
    </Modal>
  )
}

export default ThankYouModal
