import React, { useState, useEffect } from 'react'
import Button from '@components/Button/Button'
import Wrapper from '@components/Wrapper/Wrapper'
import { Link } from 'gatsby'
import PrintPage from '@components/PrintComponent/PrintPage'

const Thankyou = () => {
  const [data, setData] = useState('')

  let scoreNumber1 =
    (typeof sessionStorage !== 'undefined' &&
      sessionStorage.getItem('dataScore')) ||
    0
  useEffect(() => {
    let scoreMeter = sessionStorage.getItem('dataScore') || 0
    let meterDecide = 0
    if (scoreMeter > 0 && scoreMeter < 20) {
      meterDecide = 0
    } else if (scoreMeter >= 20 && scoreMeter < 30) {
      meterDecide = 1
    } else if (scoreMeter >= 30 && scoreMeter < 45) {
      meterDecide = 2
    } else if (scoreMeter >= 45 && scoreMeter < 70) {
      meterDecide = 3
    } else if (scoreMeter >= 70) {
      meterDecide = 4
    }
    let tempObj = {
      // image: imagePage,
      FirstName: sessionStorage.getItem('FirstName'),
      LastName: sessionStorage.getItem('LastName'),
      Title: sessionStorage.getItem('Title'),
      Company: sessionStorage.getItem('Company'),
      // meterOneImage: graphDecide,
      score: sessionStorage.getItem('dataScore') || 0,
      rfiNum: sessionStorage.getItem('rfi'),
      iceNum: sessionStorage.getItem('ice'),
      vdcNum: sessionStorage.getItem('vis'),
      ddpNum: sessionStorage.getItem('iso'),
      ddmNum: sessionStorage.getItem('ddm'),
      savingNum: sessionStorage.getItem('savings'),
      meterDecide,
    }
    setData(tempObj)
  }, [])
  return (
    <Wrapper>
      <div className="thankyou">
        <div className="thankyou__heading headingLegendBold">All Done!</div>
        <div className="thankyou__text textElement">
          Thank you for using the Integrated Digital Delivery Readiness
          Assessment and Benefit tool. Based on the input you provided your
          readiness score is:
        </div>
        <div className="thankyou__score">
          {scoreNumber1 ? scoreNumber1 : 0}/100
        </div>
        <div className="thankyou__heading headingLegendBold">
          Download Full Report
        </div>
        <div className="thankyou__text textElement">
          A detailed analysis of your current practice and recommendations is
          ready for your download now.
        </div>
        <div className="thankyou__buttons-sub-wrapper">
          <div className="thankyou__buttons-wrapper">
            <div className="back-btn-wrapper">
              <Link to="/">
                <Button label="Back to the Beginning" />
              </Link>
            </div>
            <div className="horizontal-space" />

            <PrintPage data={data} />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Thankyou
